import React from "react";
import './PriceList.css';

export const PriceList = () => {
    return (
        <React.Fragment>
            <h1>Prijslijst</h1>
            <table cellPadding="0" cellSpacing="0">
                <tr>
                    <td width="225" valign="top">
                        <b>Knippen</b><br /><br />
                        <div className="item">Knippen</div><div className="price">&euro;25</div><br />
                        <div className="item">Kinderen t/m 10 jaar</div><div className="price">&euro;23</div><br />
                        <div className="item">Knippen stylen</div><div className="price">&euro;40</div><br />
                        <div className="item">Knippen f&ouml;hnen</div><div className="price">&euro;45</div><br />
                        <div className="item">Knippen watergolven</div><div className="price">&euro;50</div><br /><br />
                        <b>Stylen</b><br /><br />
                        <div className="item">F&ouml;hnen</div><div className="price">&euro;25</div><br />
                        <div className="item">Watergolven</div><div className="price">&euro;30</div><br />
                        (Toeslag lang haar 10 euro)<br /><br />
                        <b>Permanenten</b><br /><br />
                        <div className="item">Permanent kort haar</div><div className="price">&euro;90</div><br />
                        <div className="item">Permanent deel</div><div className="price">&euro;80</div><br />
                        (Permanent is incl. knippen/f&ouml;hnen toeslag watergolf 5 euro)
                    </td>
                    <td width="50"></td>
                    <td width="225" valign="top">
                        <div className="item"><b>Kleuren</b></div><div className="price">Vanaf</div><br /><br />
                        <b>Verven</b><br /><br />
                        <div className="item">Uitgroei behandeling</div><div className="price">&euro;45</div><br />
                        <div className="item">Hele behandeling</div><div className="price">&euro;50</div><br /><br />
                        <b>Coupe soleil</b><br /><br />
                        <div className="item">Coupe soleil folie</div><div className="price">&euro;70</div><br />
                        <div className="item">Coupe soleil folie deel</div><div className="price">&euro;60</div><br /><br />
                        <div className="item">Highlights</div><div className="price">&euro;50</div><br />
                        <div className="item">Ontkleuren</div><div className="price">&euro;70</div><br />
                        (Toeslag lang haar 10 euro)<br /><br />
                        Let op:<br />
                        Woont u buiten Amstelveen dan geldt een toeslag van 2,50&euro; op alle behandelingen. Eventuele parkeer kosten worden doorberekend. 
                    </td>
                </tr>
            </table>
        </React.Fragment>
    );
}